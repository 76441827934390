// src/Footer.js

import React, { useState } from 'react';

import './Footer.css'; // For styles

const Footer = () => {

  
    return (
      
        <footer>
          Powered by <a href="#">Azure Digitalogy Consulting</a>
        </footer>
      
    );
  }

export default Footer;
