// src/components/sections/Newsletter.js
import React from "react";

const Newsletter = () => {

    return(
        <div>
            <section className="py-5 my-md-5">
                <div className="container">
                    <div className="text-center">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <span className="text-muted">Newsletter</span>
                                <h2 className="display-5 fw-bold">Subscribe Today</h2>
                                <p className="lead">Stay in the loop! Subscribe to our newsletter for the latest updates, exclusive offers, and more!</p>
                                <div className="mx-auto mt-3">
                                    <form className="row g-3">
                                        <div className="col-md-4">
                                   
                                            <input
                                                className="form-control bg-light"
                                                placeholder="Full name"
                                                type="text"
                                                
                                            />   
                                        </div>
                                        <div className="col-md-4">
                                            
                                            <input
                                                className="form-control bg-light"
                                                placeholder="Email address"
                                                type="text"
                                                
                                            />     
                                        </div>
                                        <div className="col-md-4">
                                            <div className="d-grid">
                                                <button className="btn btn-primary" type="submit">Subscribe</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>

    );


};

export default Newsletter;
