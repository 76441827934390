import React from 'react';
import './Spinner.css';


const Spinner =  ({ text = "Loading..." })  => {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '65vh' }}>
      <div className="text-center">
      <div class="spinner-grow text-light" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
        <p className="mt-3 blinking-text text-light">{text}</p>
      </div>
    </div>
  );
};
  
  export default Spinner;