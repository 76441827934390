// src/components/MultiImageCarousel.js
import React from 'react';
import { Carousel } from 'react-bootstrap';

const MultiImageCarousel = ({ items }) => {
    // Group every `n` items together for each slide
    const itemsPerSlide = 3;
    const slides = [];
    for (let i = 0; i < items.length; i += itemsPerSlide) {
        slides.push(items.slice(i, i + itemsPerSlide));
    }

    return (
        <Carousel>
            {slides.map((slide, idx) => (
                <Carousel.Item key={idx}>
                    <div className="d-flex  azure-slider">
                        {slide.map((item, index) => (
                            <div key={index} className="flex-fill">
                                <img
                                    className="d-block w-100"
                                    src={item.src}
                                    alt={item.alt}
                                    style={{ minHeight: '100px', objectFit: 'cover', height: 'auto', }}
                                />
                                
                            </div>
                        ))}
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    );
};

export default MultiImageCarousel;
