// src/components/sections/Fishing.js
import React from 'react';
import './Fishing.css';

const Fishing = () => {

    return (

        <div>
             <section className="py-5">
                <div className="container">
                    <div className="row justify-content-center text-center mb-4 mb-md-5">
                        <div className="col-xl-9 col-xxl-8">
                           
                            <h2 className="display-5 fw-bold">Fishing</h2>
                            <p className="lead">
As the sun begins its slow ascent above the horizon, casting a warm glow over the tranquil waters, the world stirs awake with the promise of adventure. It's a new day, ripe with possibilities, and for the avid angler, it's time to answer the age-old call of the wild..</p>
                        </div>
                    </div>
                    <div className="row g-5">
                        <div className="col-md-4">
                            <div className="card border-0">
                                <a href="">
                                    <img
                                        alt=""
                                        src="https://freefrontend.dev/assets/rectangle-wide.png"
                                        className="img-fluid rounded"
                                    />{' '}

                                    </a>
                                <div className="card-body p-0 text-center mt-4">
                                    <h5><a className="text-dark text-decoration-none" href="">Fishing Charters</a></h5>
                                    <p className="mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tristique nec sapien vel bibendum.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card border-0">
                                <a href="">
                                    <img
                                        alt=""
                                        src="https://freefrontend.dev/assets/rectangle-wide.png"
                                        className="img-fluid rounded"
                                    />{' '}
                                </a>
                                <div className="card-body p-0 text-center mt-4">
                                    <h5><a className="text-dark text-decoration-none" href="">Fishing the Whitsundays</a></h5>
                                    <p className="mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tristique nec sapien vel bibendum.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card border-0">
                                <a href="">
                                    <img
                                        alt=""
                                        src="https://freefrontend.dev/assets/rectangle-wide.png"
                                        className="img-fluid rounded"
                                    />{' '}
                                    
                                </a>
                                <div className="card-body p-0 text-center mt-4">
                                    <h5><a className="text-dark text-decoration-none" href="">Fishing the Reef</a></h5>
                                    <p className="mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tristique nec sapien vel bibendum.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );

};

export default Fishing;