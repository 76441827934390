// src/components/CarouselSkeleton.js
import React from 'react';
import './CarouselSkeleton.css';  // Make sure to create and import the CSS

const CarouselSkeleton = () => {
  return (
    <div className="carousel slide" data-ride="carousel">
      <div className="carousel-inner">
        {/* First carousel item with just a skeleton image */}
        <div className="carousel-item active">
          <div className="skeleton-carousel" style={{ height: '65vh', backgroundColor: '#ccc' }}>
            <div className="d-flex justify-content-center align-items-center skeleton-image" style={{ height: '100%' }}>
              <div className="text-center">
                <div className="spinner-grow text-dark" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselSkeleton;
