import React, { useState, useEffect, Suspense, lazy } from 'react';

import logo from './fish_1.svg';

import Navbar3 from './components/Navbar';
import NewsList from './components/NewsList';

import MultiImageCarousel from './components/MultiImageCarousel';

import Spinner from './components/Spinner';
import CarouselSkeleton from './components/CarouselSkeleton';
import Footer from './components/Footer';
import Fishing from './components/section/Fishing';
import Newsletter from './components/section/Newsletter';
import ContactUs from './components/section/ContactUs';
import AboutUs from './components/AboutUs';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';

import slide1 from './assets/images/carousel/slide-1.jpeg';
import slide2 from './assets/images/carousel/slide-3.jpeg';
import slide3 from './assets/images/carousel/slide-2.jpeg';

import icon_fish from './assets/images/animation/fish.gif';
import icon_fish2 from './assets/images/animation/fish_2.gif';

import slider1 from './assets/images/slider/slider_1.png';
import slider2 from './assets/images/slider/slider_2.png';
import slider3 from './assets/images/slider/slider_3.png';

{/* import Spinner from './components/Spinner'; */}

{/* import NewsCarousel from './components/NewsCarousel'; */}
{/* import TopArticles from './components/TopArticles'; */}

// Lazy load other components
{/*const NewsCarousel = lazy(() => import('./components/NewsCarousel')); */}
const NewsCarousel = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import('./components/NewsCarousel')), 1000); // Delay of 3000ms (3 seconds)
  });
});

const TopArticles = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import('./components/TopArticles')), 1500); // Delay of 3000ms (3 seconds)
  });
});


const App = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {

    document.title = "fishingairliebeach.com.au - Home"

      const fetchNews = async () => {
          const response = await fetch('https://newsapi.org/v2/top-headlines?country=us&apiKey=9f7c56d58a824fbf94f71461e61d1762');
          const data = await response.json();
          setArticles(data.articles);
      };

      fetchNews();
  }, []);

  const carousels = [
    {
        title: "Hooked on Airlie: Captivating Fishing Tales",
        summary: "",
        imageUrl: slide1
    },
    {
        title: "Reel Adventures: Exploring Airlie Beach's Fishing Gems",
        summary: "",
        imageUrl: slide2
    },
    {
        title: "Cast Away: Airlie Beach Fishing Escapades",
        summary: "",
        imageUrl: slide3
    },
    
];

const Tops = [
  {
      imageUrl: icon_fish,
      title: "Coral Trout",
      url: "#services",
  },
  {
      imageUrl: icon_fish2,
      title: "Red Emperor",
      url: "#services",
  },
  {
      imageUrl: icon_fish,
      title: "Spanish Mackarel",
      url: "#",
  },
  {
      imageUrl: icon_fish2,
      title: "Barramundi",
      url: "#",
  },
  {
      imageUrl: icon_fish,
      title: "Mangrove Jack",
      url: "#",
  },
  {
      imageUrl: icon_fish2,
      title: "Great Trevally",
      url: "#",
  },
  {
    imageUrl: icon_fish,
    title: "Queenfish",
    url: "#",
},
{
    imageUrl: icon_fish2,
    title: "Cobia",
    url: "#",
},
];

const carouselItems = [
  { src: slider1, alt: 'Image 1', caption: 'Caption 1' },
  { src: slider2, alt: 'Image 2', caption: 'Caption 2' },
  { src: slider3, alt: 'Image 3', caption: 'Caption 3' },
  { src: slider3, alt: 'Image 1', caption: 'Caption 1' },
  { src: slider1, alt: 'Image 2', caption: 'Caption 2' },
  { src: slider2, alt: 'Image 3', caption: 'Caption 3' },
  // Add more images as needed
];



  return (
    <BrowserRouter>
      <div>
          <Navbar3 />
       
            <section id="home" className="hero-section py-5">
            <div className="container-fluid">
              <div className="row  equal-height">
                <div className="col-md-9">
                  {/* Content for the first column */}
                  <div className="bg-primary text-white p-3 flex-fill">
                    {/* <Suspense fallback={<Spinner text="Please wait..."  />}> */}
                    <Suspense fallback={<CarouselSkeleton/>}>
                      <NewsCarousel articles={carousels} />
                    </Suspense>  
                  </div>
                </div>
                <div className="col-md-3">
                  {/* Content for the second column */}
                  <div className="bg-primary text-white p-3 flex-fill">
                    <Suspense fallback={<Spinner text="Please wait..."  />}>
                      <TopArticles articles={Tops} />
                    </Suspense>
                  </div>
                </div>
              </div>
              <div className="row sliderbottom">
                <div className="col-md-12">
                  <MultiImageCarousel items={carouselItems} />
                </div>
              </div>
            </div>
            </section>
          
          <section id="about" className="standard-section">
            <AboutUs/>
            
          </section>
          <section id="contact" className="standard-section">
            <Newsletter/>
          </section>
          <section id="fishing" className="standard-section">
            <Fishing/>

          </section>
          <section id="contact" className="standard-section">
            <ContactUs/>
          </section>
          <Footer />
          {/*
          <div className="container mt-3">
              <NewsList articles={articles} />
          </div>
          */}
      </div>
      </BrowserRouter>
  );
};

export default App;
