// src/components/Navbar3.js
import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Navbar.css';
import { HashLink } from 'react-router-hash-link';

const NavigationBar = () => {
  return (
      <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
          <Container>
              <Navbar.Brand as={HashLink} smooth to="#home">
                  <img
                      alt="News Site Logo"
                      src="https://via.placeholder.com/50"
                      width="30"
                      height="30"
                      className="d-inline-block align-top"
                  />{' '}
                  fishingairliebeach.com.au
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="ms-auto">  
                      <Nav.Link as={HashLink} smooth to="#about">About us</Nav.Link>
                      <Nav.Link as={HashLink} smooth to="#fishing">Fishing</Nav.Link>
                      <Nav.Link as={HashLink} smooth to="#home">Newsletter</Nav.Link>
                      <Nav.Link as={HashLink} smooth to="#contact">Contact</Nav.Link>
                  </Nav>
              </Navbar.Collapse>
          </Container>
      </Navbar>
  );
};

export default NavigationBar;